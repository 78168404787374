import { toast } from "react-toastify";

export const errorMessage = msg => {
    toast.error(msg);
};

// Error handler
export function handleError(error) {
    if (process.env.NODE_ENV === "production" && Sentry) {
        Sentry.captureException(error);
    }

    if (!error) {
        // return errorMessage("Unexpected error occured.");
        return;
    }

    if (error.response) {
        error = error.response;
    }

    if (error.errorName) {
        error = {
            ...error,
            data: { errorName: error.errorName, errorMessage: error.errorMessage },
        };
    }

    if (!error.data) {
        if (error.message) {
            return errorMessage(error.message);
        }

        return errorMessage(error);
    }

    switch (error.data.errorName) {
        case "ValidationError":
            return typeof error.data.errorMessage === "string" && toast.error(error.data.errorMessage);
        case "EmailAddressAlreadyExists":
            return typeof error.data.errorMessage === "string" && toast.error(error.data.errorMessage);
        case "PasswordIsTooShort":
            return typeof error.data.errorMessage === "string" && toast.error(error.data.errorMessage);
        case "NoUserFound":
            return errorMessage("Your username or password is incorrect");
        case "UserBlacklisted":
            return errorMessage("Your account is temporarily restricted, please contact support");
        case "PasswordIsWrong":
            return errorMessage("You have entered an incorrect password");
        case "2faCodeMisMatch":
            return errorMessage("The 6 digit 2FA code entered is incorrect.");
        case "2faNotSetup":
            return errorMessage("2FA is not enabled on this account.");
        case "2faAlreadySetup":
            return errorMessage("2FA is already enabled on this account.");
        // case "NoEmailFound":
        //     return; // Return nothing, we shouldn't let the user know if this account does exist for extra security
        default:
            if (
                error.data &&
                typeof error.data === "object" &&
                error.data.hasOwnProperty("errorMessage") &&
                typeof error.data.errorMessage === "string"
            ) {
                return errorMessage(error.data.errorMessage);
            }

            // if (typeof error.data === "string") {
            //     return errorMessage(error.data);
            // }
            //
            // if (error.data && typeof error.data === "object" && error.data.hasOwnProperty("errorName")) {
            //     return errorMessage(error.data.errorName);
            // }

            return;
    }
}
